
import { Options, Vue } from 'vue-class-component';
import AddAnime from './AddTabs/Anime/AddAnime.vue';
import AddManga from './AddTabs/Manga/AddManga.vue';
import AddShortcut from './AddTabs/Shortcut/AddShortcut.vue';
import { Modal } from 'bootstrap'
import axios from 'axios'
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'

@Options({
    props: {},
    components: {
        AddAnime,
        AddManga,
        AddShortcut
    },
})
export default class Navbar extends Vue {
    store = useStore(key)
    mounted() {
        axios.post(
            this.store.state.BaseUrl + 'Shortcut/FetchShortcut.php',
            {
                User: this.store.state.User,
            },
        )
            .then((response) => {
                if (response.data != false) {
                    this.store.state.Shortcuts = response.data
                }
            }).catch(function (error) {
                console.log(error)
            });
    }
}
