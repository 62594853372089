
import { computed } from '@vue/reactivity';
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'

@Options({
    props: {}
})
export default class Notification extends Vue {
}

