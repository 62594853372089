
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'

@Options({
    props: {},
    components: {
    }
})
export default class InputTag extends Vue {
    tags = ['hello', 'world'];
    newTag = ''
    paddingLeft = 10;

    mounted() {
        this.setLeftPadding()
        this.$watch('tags', async () => {
            await this.$nextTick()
            this.setLeftPadding()
        }, { deep: true });
    }


    addTag(tag: any) {
        if (tag != "") {
            this.tags.push(tag);
            this.newTag = ""; // reset newTag
        }
    }

    removeTag(index: number) {
        this.tags.splice(index, 1);
    }

    setLeftPadding() {
        const extraCushion = 15
        this.paddingLeft = (this.$refs.tagsUl as any).clientWidth + extraCushion;
    }
}
