
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import General from './General/General.vue'
import Notification from './Notification/Notification.vue'

@Options({
    props: {},
    components: {
    General,
    Notification,
  },
})
export default class MyAccount extends Vue {
}
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
