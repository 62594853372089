
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import PokemonCard from './PokemonCard/PokemonCard.vue'
@Options({
    props: {},
    components: {
        PokemonCard
    }
})
export default class MyPokeWorld extends Vue {
    store = useStore(key)
    Pokemons: any[] = []
    mounted() {
        if (this.store.state.User?.pokemon == "") {
            this.store.state.User.pokemon = []
        }

        this.$watch('store.state.User.pokemon', () => {
            axios.post(
                this.store.state.BaseUrl + 'MyPokeWorld/UpdatePokemon.php',
                {
                    User: this.store.state.User
                },
            )
        }, { deep: true })

        axios.get(
            this.store.state.BaseUrl + 'MyPokeWorld/pokemon.json'
        )
            .then((response) => {
                this.Pokemons = (response.data as any[])
            }).catch(function (error) {
                console.log(error)
            });
    }
}
