

export class ItemsClass {
  id = 0
  parents = 0
  volume = 0
  statut = "NotOut"
  date = null
  date_arrive = null
  constructor(Volemume: number) {
    this.volume = Volemume;
  }
}

export default class MangathequeClass {
  id = 0
  id_user = 0
  name = ""
  statut = "en cour"
  complete = 0
  format = "none"
  items: ItemsClass[] = []
  constructor(UserId: number) {
    this.id_user = UserId;
  }
}

