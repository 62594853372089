
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'

@Options({
    props: {
        Pokemon: {}
    }
})
export default class PokemonCard extends Vue {
    store = useStore(key)
    Pokemon!: any
    IsCath: "catch" | "not-catch" = "not-catch"

    mounted() {
        if ((this.store.state.User?.pokemon as any[]).find(element => element == this.Pokemon.id) != null) {
            this.IsCath = "catch"
        }
    }
    
    Catch() {
        if (this.IsCath == "catch") {
            this.IsCath = "not-catch";
            (this.store.state.User?.pokemon as any[]).splice((this.store.state.User?.pokemon as any[]).findIndex(element => element == this.Pokemon.id),1)
        } else if (this.IsCath == "not-catch") {
            this.IsCath = "catch";
            (this.store.state.User?.pokemon as any[]).push(this.Pokemon.id)
        }
    }
}
