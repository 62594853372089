
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import UnitBbs from '@/Class/UnitBbs.vue'
@Options({
    props: {
        AllCharacterSelected: [],
        AllCharacter: []
    },
    components: {
    }
})
export default class SelectUnitAlbum extends Vue {
    store = useStore(key)
    AllCharacterSelected!: any[]
    AllCharacter!: any[]
    AlbumCharacter = new UnitBbs()

    AreAllUnitInAlbum() {
        return this.AllCharacterSelected.every((value: any) => this.store.state.bbs.album.includes(value))
    }

    AddSelectionToAlbum() {
        this.AllCharacterSelected.forEach((element) => {
            if (this.store.state.bbs.album.findIndex((album) => album == element) == -1) {
                this.store.state.bbs.album.push(element)
            }
        })
    }

    SaveSelection() {
        this.AllCharacterSelected.forEach((element) => {
            element['5S'] = this.AlbumCharacter['5S']
            element['6S'] = this.AlbumCharacter['6S']
            if (this.AllCharacter.find((all) => all.id == element.id).banners.length == 3) {
                element['6SR'] = this.AlbumCharacter['6SR']
            }
        })
    }
}
