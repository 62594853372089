import { vModelText as _vModelText, withKeys as _withKeys, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27aab065"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tag-input" }
const _hoisted_2 = {
  class: "tags",
  ref: "tagsUl"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.newTag) = $event)),
      type: "text",
      onKeydown: [
        _cache[1] || (_cache[1] = _withKeys($event => (_ctx.addTag(_ctx.newTag)), ["enter"])),
        _cache[2] || (_cache[2] = _withKeys(_withModifiers($event => (_ctx.addTag(_ctx.newTag)), ["prevent"]), ["tab"])),
        _cache[3] || (_cache[3] = _withKeys($event => (_ctx.newTag.length || _ctx.removeTag(_ctx.tags.length - 1)), ["delete"]))
      ],
      style: _normalizeStyle('padding-left:' + _ctx.paddingLeft +'px')
    }, null, 36), [
      [_vModelText, _ctx.newTag]
    ]),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: tag,
          class: "tag"
        }, [
          _createTextVNode(_toDisplayString(tag) + " ", 1),
          _createElementVNode("button", {
            class: "delete",
            onClick: $event => (_ctx.removeTag(index))
          }, "x", 8, _hoisted_3)
        ]))
      }), 128))
    ], 512)
  ]))
}