
import { Options, Vue } from 'vue-class-component';
import { Manga } from '@/interfaces/Manga'
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'

@Options({
    props: {
        Manga: {}
    },
    components: {},
})
export default class MangaCard extends Vue {
    store = useStore(key)
    Manga!: Manga
    SortMangaStatut = ["Reading", "On hold", "Stoped", "Plan to read", "Finish"]
    holdUpdate = false
    timeHold = new Date()
    statut = ""
    currently = ""
    timeOpen = new Date();

    mounted() {
        this.currently = this.Manga.currently
        if (!this.store.state.AnilistData.AnilistManga[this.Manga.anilistId] && this.Manga.anilistId != 0) {
            this.queryAnilist()
        }

        this.$watch('currently', () => {
            if (this.currently != "true" && this.currently != "false") {
                this.currently = this.currently.toString()
            }
        })

        const myModal = document.getElementById('MangaEditModal'+ this.Manga.id);

        myModal?.addEventListener('hidden.bs.modal', () => {
            this.store.state.openedModalId = ""
        });
    }

    queryAnilist() {
        const query = `
    query ($id: Int) { # Define which variables will be used in the query (id)
      Media (id: $id, type: MANGA) { # Insert our variables into the query arguments (id) (type: ANIME is hard-coded in the query)
        id
        bannerImage
        title {
          romaji
          english
          native
        }
        coverImage{
          large
          medium
        }
      }
    }
    `;

        const variables = {
            id: this.Manga.anilistId
        };
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };
        axios.post(
            'https://graphql.anilist.co',
            {
                query: query,
                variables: variables
            },
        )
            .then((response) => {
                this.store.state.AnilistData.AnilistManga[this.Manga.anilistId] = response.data
            }).catch(function (error) {
                console.log(error)
            });
    }

    CheckUpdate(){
        if(this.store.state.NeedToReload){
            axios.post(
            this.store.state.BaseUrl + 'MyMangaAnime/FetchAManga.php',
            {
                User: this.store.state.User,
                Manga: this.Manga
            },
            ).then((response) => {
                this.Manga.aJour = (response.data as Manga).aJour;
                this.Manga.date = (response.data as Manga).date;
                this.UpdateManga() 
            })
        }else{
            this.UpdateManga() 
        }
    }

    UpdateManga() {
        this.Manga.currently = this.currently

        axios.post(
            this.store.state.BaseUrl + 'MyMangaAnime/UpdateManga.php',
            {
                User: this.store.state.User,
                Manga: this.Manga
            },
        ).then((response) => {
            this.store.state.Mangas = (this.store.state.Mangas as Manga[]).sort((a, b) => (a.alternativName > b.alternativName) ? 1 : ((b.alternativName > a.alternativName) ? -1 : 0));
            this.store.state.Mangas = (this.store.state.Mangas as Manga[]).sort((a, b) => this.SortMangaStatut.indexOf(a.statut) - this.SortMangaStatut.indexOf(b.statut))
            this.store.state.Mangas = (this.store.state.Mangas as Manga[]).sort((a, b) => (a.aJour < b.aJour) ? 1 : ((b.aJour < a.aJour) ? -1 : 0));
            this.UpdateAniliste()
        })
    }

    UpdateAniliste() {
        if (this.Manga.anilistId == 0 && this.store.state.User?.anilist_token == null) return

        let status = "CURRENT"
        switch (this.Manga.statut) {
            case "Reading":
                status = "CURRENT"
                break;
            case "Stoped":
                status = "DROPPED"
                break;
            case "On hold":
                status = "PAUSED"
                break;
            case "Plan to read":
                status = "PLANNING"
                break;
            case "Finish":
                status = "COMPLETED"
                break;
        }
        const query = `
                    mutation($mediaId: Int,$progress: Int, $status: MediaListStatus) {
                        SaveMediaListEntry(mediaId: $mediaId,progress: $progress, status: $status) {
                            id
                            status
                        }
                    }
                    `;
        const variables = {
            "progress": this.Manga.scan,
            "mediaId": this.Manga.anilistId,
            "status": status
        };
        var options = {
            uri: 'https://graphql.anilist.co',
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.store.state.User?.anilist_token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                'query': query,
                variables: variables
            })
        };
        fetch("https://graphql.anilist.co", options as any)
    }

    DeleteManga() {
        axios.post(
            this.store.state.BaseUrl + 'MyMangaAnime/DeleteManga.php',
            {
                User: this.store.state.User,
                Manga: this.Manga
            },
        )
            .then((response) => {
                this.store.state.Mangas = this.store.state.Mangas?.filter(element => element != this.Manga) as Manga[]
            }).catch(function (error) {
                console.log(error)
            });
    }

    SelectManga(id: string){
        this.store.state.openedModalId = id;
        this.statut = this.Manga.statut;
        this.timeOpen = new Date()
    }
}
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
