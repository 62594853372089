
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { User } from '@/interfaces/User'
import InputTag from './InputTag/InputTag.vue'

@Options({
    props: {},
    components: {
        InputTag
  },
})
export default class MyAdminPanel extends Vue {
    mounted(){
        console.log((this.$refs.test as InputTag).tags)
    }
}
