import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-507d4020"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "card-text" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["src"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.GameSelected == '')
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.Games, (Game) => {
          return (_openBlock(), _createElementBlock("div", null, [
            _createElementVNode("div", {
              class: "card",
              style: {"width":"18rem"},
              onClick: $event => (_ctx.SelectGame(Game))
            }, [
              _createElementVNode("img", {
                src: Game.thumbnail,
                class: "card-img-top"
              }, null, 8, _hoisted_2),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h5", _hoisted_4, _toDisplayString(Game.name), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString(Game.description), 1)
              ])
            ], 8, _hoisted_1)
          ]))
        }), 256))
      : _createCommentVNode("", true),
    (_ctx.GameSelected != '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("iframe", {
            src: 'https://otakuworld.org/Unity/' + _ctx.GameSelected + '/',
            name: "iframe"
          }, null, 8, _hoisted_7)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}