
export default class ClassManga {
  aJour = 0
  alternativName = ""
  alternativImage = ""
  anilistId = 0
  date = new Date()
  doCheck = false
  frequency = 0
  id = 0
  id_user = 0
  link = ""
  songLink = ""
  scan = 0
  statut = "Reading"
  currently = "false"

  constructor(UserId: number) {
    this.id_user = UserId;
  }
}
