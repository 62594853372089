
import { computed } from '@vue/reactivity';
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import { User } from '@/interfaces/User'
import axios from 'axios'
import Draggable from 'vuedraggable'

@Options({
    props: {},
    components: {
        Draggable
    }
})
export default class General extends Vue {
    store = useStore(key)
    User: any = {
        Login: "",
        Password: "",
        PasswordConfirm: "",
        DefaultModule: 0,
    }
    PasswordsMatch = true

    mounted() {
        this.User.Login = this.$cookies.get("User").Login
        this.User.Password = this.$cookies.get("User").Password
        this.User.PasswordConfirm = this.$cookies.get("User").Password
        this.User.DefaultModule = this.store.state.User?.defaultModule;

        this.$watch('User', () => {
            if (this.User.Password != this.User.PasswordConfirm) {
                this.PasswordsMatch = false
            } else {
                this.PasswordsMatch = true
            }
        }, { deep: true })

        this.$watch('store.state.Modules', () => {
            this.SaveModuleOrder()
            this.store.state.ModulesFilted = this.store.state.Modules.filter(element => element.name != this.store.state.Module) as any[]
        })
    }

    SaveLogs() {
        axios.post(
            this.store.state.BaseUrl + 'MyAccount/UpdateLogs.php',
            {
                Logs: this.User,
                User: this.store.state.User
            },
        ).then((response) => {
            this.$cookies.set("User", { Login: this.User.Login, Password: this.User.Password })
        }).catch(function (error) {
            console.log(error)
        });
    }

    SaveModuleOrder(){
        axios.post(
            this.store.state.BaseUrl + 'MyAccount/UpdateModulesOrder.php',
            {
                ModulesOrder: this.store.state.Modules,
                User: this.store.state.User
            },
        )
    }
}
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
