
import { Options, Vue } from 'vue-class-component';
import { Anime } from '@/interfaces/Anime'
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'

@Options({
    props: {
        Anime: {}
    }
})
export default class AnimeCard extends Vue {
    store = useStore(key)
    Anime!: Anime
    SortAnimeStatut = ["Watching", "On hold", "Stoped", "Plan to watch", "Finish"]
    currently = ""
    holdUpdate = false
    timeHold = new Date()

    mounted() {
        this.currently = this.Anime.currently
        if (!this.store.state.AnilistData.AnilistAnime[this.Anime.anilistId] && this.Anime.anilistId != 0) {
            this.queryAnilist()
        }

        this.$watch('currently', () => {
            if (this.currently != "true" && this.currently != "false") {
                this.currently = this.currently.toString()
            }
        })
    }

    queryAnilist() {
        const query = `
    query ($id: Int) { # Define which variables will be used in the query (id)
      Media (id: $id, type: ANIME) { # Insert our variables into the query arguments (id) (type: ANIME is hard-coded in the query)
        id
        bannerImage
        title {
          romaji
          english
          native
        }
        coverImage{
          large
          medium
        }
      }
    }
    `;

        const variables = {
            id: this.Anime.anilistId
        };
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };

        axios.post(
            'https://graphql.anilist.co',
            {
                query: query,
                variables: variables
            },
        )
            .then((response) => {
                this.store.state.AnilistData.AnilistAnime[this.Anime.anilistId] = response.data
            }).catch(function (error) {
                console.log(error)
            });
    }

    CheckUpdate(){
        if(this.store.state.NeedToReload){
            axios.post(
            this.store.state.BaseUrl + 'MyMangaAnime/FetchAnAnime.php',
            {
                User: this.store.state.User,
                Anime: this.Anime
            },
            ).then((response) => {
                this.Anime.aJour = (response.data as Anime).aJour;
                this.Anime.date = (response.data as Anime).date;
                this.UpdateAnime() 
            })
        }else{
            this.UpdateAnime() 
        }
    }

    UpdateAnime() {
        this.Anime.currently = this.currently
        axios.post(
            this.store.state.BaseUrl + 'MyMangaAnime/UpdateAnime.php',
            {
                User: this.store.state.User,
                Anime: this.Anime
            },
        ).then((response) => {
            this.store.state.Animes = (this.store.state.Animes as Anime[]).sort((a, b) => (a.alternativName > b.alternativName) ? 1 : ((b.alternativName > a.alternativName) ? -1 : 0));
            this.store.state.Animes = (this.store.state.Animes as Anime[]).sort((a, b) => this.SortAnimeStatut.indexOf(a.statut) - this.SortAnimeStatut.indexOf(b.statut))
            this.store.state.Animes = (this.store.state.Animes as Anime[]).sort((a, b) => (a.aJour < b.aJour) ? 1 : ((b.aJour < a.aJour) ? -1 : 0));
            this.UpdateAniliste()
        })
    }

    UpdateAniliste() {
        if (this.Anime.anilistId == 0 && this.store.state.User?.anilist_token == null) return

        let status = "CURRENT"
        switch (this.Anime.statut) {
            case "Watching":
                status = "CURRENT"
                break;
            case "Stoped":
                status = "DROPPED"
                break;
            case "On hold":
                status = "PAUSED"
                break;
            case "Plan to watch":
                status = "PLANNING"
                break;
            case "Finish":
                status = "COMPLETED"
                break;
        }
        const query = `
                    mutation($mediaId: Int,$progress: Int, $status: MediaListStatus) {
                        SaveMediaListEntry(mediaId: $mediaId,progress: $progress, status: $status) {
                            id
                            status
                        }
                    }
                    `;
        const variables = {
            "progress": this.Anime.episode,
            "mediaId": this.Anime.anilistId,
            "status": status
        };
        var options = {
            uri: 'https://graphql.anilist.co',
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.store.state.User?.anilist_token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                'query': query,
                variables: variables
            })
        };
        fetch("https://graphql.anilist.co", options as any)
    }

    DeleteAnime() {
        axios.post(
            this.store.state.BaseUrl + 'MyMangaAnime/DeleteAnime.php',
            {
                User: this.store.state.User,
                Anime: this.Anime
            },
        )
            .then((response) => {
                this.store.state.Animes = this.store.state.Animes?.filter(element => element != this.Anime) as Anime[]
            }).catch(function (error) {
                console.log(error)
            });
    }

    SelectAnime(id: string){
        this.store.state.openedModalId = id;
    }
}
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
