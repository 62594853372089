
import { Options, Vue } from 'vue-class-component';
import { key } from './store'
import { storeKey, useStore } from 'vuex'
import { computed } from 'vue'
import Home from './components/Home/Home.vue'
import MyJapanWorld from './components/MyJapanWorld/MyJapanWorld.vue'
import DiscordAttack from './components/DiscordAttack/DiscordAttack.vue'

@Options({
  components: {
    Home,
    MyJapanWorld,
    DiscordAttack
  },
})
export default class App extends Vue {
  store = useStore(key)
  page = ""
  allow = false

  async mounted() {

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);


    if (urlParams.has('page')) {
      this.page = urlParams.get('page') as string;
    }

    await fetch("./Text.json").then(async (res) => {
      this.store.state.TextConfig = await res.json()
    })
  }
}
