
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'

@Options({
    props: {},
    components: {
    }
})
export default class Lesson extends Vue {
    store = useStore(key)
    Lessons: any = null
    ChoosenLesson :any = null
    ChoosenSection :any = null

    mounted(): void {
        axios.post(
            this.store.state.BaseUrl + 'MyJapanWorld/FetchAllLessons.php',
            {},
        )
            .then((response) => {
                console.log(response.data)
               this.Lessons = response.data
            }).catch(function (error) {
                console.log(error)
            });
    }

    blobToImage = (blob: any) => {
        return URL.createObjectURL(blob)
    }


}
