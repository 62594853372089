
import { Options, Vue } from 'vue-class-component';
import axios from 'axios'
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'

@Options({
    props: {},
    components: {
    },
})
export default class AddShortcut extends Vue {
    newShortcut = { name: "", link: "", color: "primary", id: -1 }
    store = useStore(key)

    AddShortcut() {
        axios.post(
            this.store.state.BaseUrl + 'Shortcut/AddShortcut.php',
            {
                User: this.store.state.User,
                Shortcut: this.newShortcut,
            },
        )
            .then((response) => {
                console.log(response.data)
                this.newShortcut.id = response.data
                this.store.state.Shortcuts.push(this.newShortcut)
            }).catch(function (error) {
                console.log(error)
            });
    }

    DeleteShortcut() {
        axios.post(
            this.store.state.BaseUrl + 'Shortcut/DeleteShortcut.php',
            {
                User: this.store.state.User,
                id: (this.$refs.deleteShortcut as any).value
            },
        )
            .then((response) => {
                this.store.state.Shortcuts = this.store.state.Shortcuts.filter(element => element.id != (this.$refs.deleteShortcut as any).value)
            }).catch(function (error) {
                console.log(error)
            });
    }
}
