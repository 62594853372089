
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { assertNewExpression } from '@babel/types';
import { InputHTMLAttributes } from 'vue';

@Options({
    props: {},
    components: {
    }
})
export default class Kana extends Vue {
    store = useStore(key)
    ActualKanaAnswer: any = null
    allKana: any = []
    goodAnswer = 0
    wrongAnswer = 0
    IndexHiraganaKatakana = 0;
    Loading = true;
    AllWrongAnswer: any = []
    Katakana = true;
    Hiragana = true;

    async mounted() {
        axios.get(this.store.state.BaseUrl + 'MyJapanWorld/Hiragana.json').then((response) => {
            this.allKana.push(response.data)
            axios.get(this.store.state.BaseUrl + 'MyJapanWorld/Katakana.json').then((response) => {
                this.allKana.push(response.data)
                console.log(this.allKana)
                this.Loading = false
                this.SelectRandomKana()
            }).catch((e) => {
                console.log(e)
            })
        }).catch((e) => {
            console.log(e)
        })

        window.addEventListener("keydown", e => {
            if (e.key == 'Enter') {
                this.Answer()
            }
        });
    }

    SelectRandomKana() {
        (this.$refs['inputKana'] as InputHTMLAttributes).value = ''
        if(this.Katakana && this.Hiragana){
            this.IndexHiraganaKatakana = Math.round(Math.random() * 1)
        }else if(this.Hiragana){
            this.IndexHiraganaKatakana = 0
        }else if(this.Katakana){
            this.IndexHiraganaKatakana = 1
        }
        
        this.ActualKanaAnswer = this.allKana[this.IndexHiraganaKatakana][Math.round(Math.random() * (Object.keys(this.allKana[this.IndexHiraganaKatakana]).length - 1))]
    }

    Answer() {
        if (this.ActualKanaAnswer["roumaji"] == ((this.$refs['inputKana'] as InputHTMLAttributes).value).toLowerCase()) {
            this.goodAnswer += 1
        } else {
            this.AllWrongAnswer.push(this.ActualKanaAnswer)
            this.wrongAnswer += 1
        }
        this.SelectRandomKana()
    }
}
