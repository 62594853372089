
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { User } from '@/interfaces/User'
import UnitInformation from './UnitInformation/UnitInformation.vue'
import UnitFarm from './UnitFarm/UnitFarm.vue'
import UnitAlbum from './UnitAlbum/UnitAlbum.vue'
import UnitBbs from '@/Class/UnitBbs.vue'

@Options({
    props: {
        Character: {},
        AlmbumMode: false,
        SelectMode: false
    },
    components: {
        UnitInformation,
        UnitFarm,
        UnitAlbum
    },
    emits: {
        ChildMounted: () => true,
    }
})
export default class UnitBBS extends Vue {
    store = useStore(key)
    Character!: any
    Album = new UnitBbs()
    InAlbum = false
    AlmbumMode!: boolean
    SelectMode!: boolean
    level: any

    beforeMount() {
        this.Album.setId(this.Character.id)
        if (this.store.state.bbs.album.find((element) => element.id == this.Album.id) != null) {
            this.Album = this.store.state.bbs.album.find((element) => element.id == this.Album.id)
            this.InAlbum = true
        }

        if (this.Album['6SR'].get) {
            this.level = this.Album['6SR'].lv
        } else if (this.Album['6S'].get) {
            this.level = this.Album['6S'].lv
        } else if (this.Album['5S'].get) {
            this.level = this.Album['5S'].lv
        }

        this.$emit('ChildMounted')
    }
}
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
