
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import UnitBbs from '@/Class/UnitBbs.vue'

@Options({
    props: {
        Character: {},
        AlbumCharacter: {},
    },
    components: {
    }
})
export default class UnitAlbum extends Vue {
    store = useStore(key)
    Character!: any
    AlbumCharacter!: any
    InAlbum = false
    
    mounted(){
        if (this.store.state.bbs.album.find((element) => element.id == this.AlbumCharacter.id) != null) {
            this.InAlbum = true
        }
    }

    AddToAlbum(){
        this.store.state.bbs.album.push(this.AlbumCharacter)
        this.InAlbum = true
    }
}
