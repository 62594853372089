import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_InputTag = _resolveComponent("InputTag")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_InputTag, { ref: "test" }, null, 512),
    _createVNode(_component_InputTag),
    _createVNode(_component_InputTag),
    _createVNode(_component_InputTag)
  ], 64))
}