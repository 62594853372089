
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { Offcanvas } from 'bootstrap'
import Module from './Module/Module.vue'

@Options({
    props: {},
    components: {
        Module
    },
})
export default class Modules extends Vue {
    store = useStore(key)
    mounted() {
        this.$watch('store.state.Module', () => {
            this.store.state.ModulesFilted = this.store.state.Modules.filter(element => element.name != this.store.state.Module && element.canBeView == 1) as any[]
        })

        axios.post(
            this.store.state.BaseUrl + 'Modules/FetchModules.php',
            {
                User: this.store.state.User,
            },
        )
            .then((response) => {
                if (response.data != false) {
                    let array: any[] = [];
                    this.store.state.User?.modulesOrder.forEach(element => {
                        if (response.data.find((data: any) => data.id == element.id && data.name == element.name && data.module == element.module && data.banner == element.banner) != null) {
                            array.push(element)
                        }
                    });
                    response.data.forEach((element: any) => {
                        if (this.store.state.User?.modulesOrder.find((data: any) => data.id == element.id && data.name == element.name && data.module == element.module && data.banner == element.banner) == null) {
                            array.push(element)
                        }
                    });
                    this.store.state.Modules = array
                    this.store.state.Module = this.store.state.Modules.find((element) => element.id == this.store.state.User?.defaultModule).module
                }
            }).catch(function (error) {
                console.log(error)
            });
        const offCanvas = new Offcanvas(this.$refs.OffcanvasModule as any)
        window.addEventListener("keydown", e => {
            if (e.key == 'Escape') {
                offCanvas.toggle(this.$refs.OffcanvasModule as any)
            }
        });
    }
}
