
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import Login from '../Login/Login.vue'
import MyMangaAnime from '../MyMangaAnime/MyMangaAnime.vue'
import MyPokeWorld from '../MyPokeWorld/MyPokeWorld.vue'
import MyAccount from '../MyAccount/MyAccount.vue'
import MyAdminPanel from '../MyAdminPanel/MyAdminPanel.vue'
import MyBBS from '../MyBBS/MyBBS.vue'
import Modules from '../Modules/Modules.vue'
import MyGameWorld from '../MyGameWorld/MyGameWorld.vue'
import MyMangatheque from '../MyMangatheque/MyMangatheque.vue'
import TestWorld from '../TestWorld/TestWorld.vue'

@Options({
    props: {},
    components: {
        Login,
        MyMangaAnime,
        Modules,
        MyPokeWorld,
        MyAccount,
        MyAdminPanel,
        MyBBS,
        MyGameWorld,
        MyMangatheque,
        TestWorld,
    }
})
export default class Home extends Vue {
    store = useStore(key)
}
