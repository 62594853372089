
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { User } from '@/interfaces/User'

@Options({
    props: {}
})
export default class Login extends Vue {
    Form = {
        Login: "",
        Password: ""
    }
    store = useStore(key)
    NotLogged = false

    mounted() {
        if (this.$cookies.isKey("User")) {
            if (this.$cookies.get("User").Login != null && this.$cookies.get("User").Password) {
                this.Form = { Login: this.$cookies.get("User").Login, Password: this.$cookies.get("User").Password }
                this.Login()
            } else {
                this.NotLogged = true;
            }
        } else {
            this.NotLogged = true;
        }

    }

    Login() {
        axios.post(
            this.store.state.BaseUrl + 'Login/Log.php',
            {
                Login: this.Form.Login,
                Password: this.Form.Password
            },
        )
            .then((response) => {
                if (response.data != false) {
                    this.store.state.User = response.data
                    console.log(this.store.state.User)
                    this.$cookies.set("User", this.Form)
                } else {
                    this.NotLogged = true;
                }
            }).catch((error) => {
                this.NotLogged = true;
            });
    }
}
