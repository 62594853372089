
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { User } from '@/interfaces/User'
import { Bbs } from '@/interfaces/Bbs'

@Options({
    props: {
        Character: {}
    },
    components: {
    }
})
export default class UnitFarm extends Vue {
    store = useStore(key)
    Character!: any

    mounted() {
        this.$el.querySelector(".collapse").collapse
    }

    AddToFarmList(version: any, itemType: "cristaux" | "gemmes" | "raid" | "larmes" | "rubans") {
        let character
        switch (itemType) {
            case "cristaux":
            case "gemmes":
                character = { images: version.image, Items: version.tree.pieces.slice(0, 15) }
                for (let index = 0; index < 15; index++) {
                    if ((this.store.state.bbs as Bbs)[itemType].find(element => element.image == version.tree.pieces[index].image)) {
                        const ArrayIndex = (this.store.state.bbs as Bbs)[itemType].findIndex(element => element.image == version.tree.pieces[index].image);
                        (this.store.state.bbs as Bbs)[itemType][ArrayIndex].amount += version.tree.pieces[index].amount;
                        (this.store.state.bbs as Bbs)[itemType][ArrayIndex].characterImage.push(character);
                    } else {
                        const DefaultItemFarm = { image: version.tree.pieces[index].image, amount: version.tree.pieces[index].amount, characterImage: [character] };
                        (this.store.state.bbs as Bbs)[itemType].push(DefaultItemFarm)
                    }
                }
                break
            case "raid":
                character = { images: version.image, Items: version.tree.raid }
                for (let index = 0; index < version.tree.raid.length; index++) {
                    if ((this.store.state.bbs as Bbs).raid.find(element => element.image == version.tree.raid[index].image)) {
                        const ArrayIndex = (this.store.state.bbs as Bbs).raid.findIndex(element => element.image == version.tree.raid[index].image);
                        (this.store.state.bbs as Bbs).raid[ArrayIndex].amount += version.tree.raid[index].amount;
                        (this.store.state.bbs as Bbs).raid[ArrayIndex].characterImage.push(character);

                    } else {
                        const DefaultItemFarm = {
                            image: version.tree.raid[index].image,
                            background: version.tree.raid[index].background,
                            frame: version.tree.raid[index].frame,
                            attribute: version.tree.raid[index].attribute,
                            amount: version.tree.raid[index].amount,
                            characterImage: [character]
                        };
                        (this.store.state.bbs as Bbs).raid.push(DefaultItemFarm)
                    }
                }
                break
            case "larmes":
                character = { images: version.image, Items: version.tree.pieces.slice(15, 20) }
                for (let index = 0; index < 5; index++) {
                    if ((this.store.state.bbs as Bbs).larmes.find(element => element.image == version.tree.pieces[index + 15].image)) {
                        const ArrayIndex = (this.store.state.bbs as Bbs).larmes.findIndex(element => element.image == version.tree.pieces[index + 15].image);
                        (this.store.state.bbs as Bbs).larmes[ArrayIndex].amount += version.tree.pieces[index + 15].amount;
                        (this.store.state.bbs as Bbs).larmes[ArrayIndex].characterImage.push(character);
                    } else {
                        const DefaultItemFarm = { image: version.tree.pieces[index + 15].image, amount: version.tree.pieces[index + 15].amount, characterImage: [character] };
                        (this.store.state.bbs as Bbs).larmes.push(DefaultItemFarm)
                    }
                }
                break
            case "rubans":
                character = { images: version.image, Items: version.tree.pieces.slice(20, 21) }
                for (let index = 0; index < 1; index++) {
                    if ((this.store.state.bbs as Bbs).rubans.find(element => element.image == version.tree.pieces[index + 20].image)) {
                        const ArrayIndex = (this.store.state.bbs as Bbs).rubans.findIndex(element => element.image == version.tree.pieces[index + 20].image);
                        (this.store.state.bbs as Bbs).rubans[ArrayIndex].amount += version.tree.pieces[index + 20].amount;
                        (this.store.state.bbs as Bbs).rubans[ArrayIndex].characterImage.push(character);
                    } else {
                        const DefaultItemFarm = { image: version.tree.pieces[index + 20].image, amount: version.tree.pieces[index + 20].amount, characterImage: [character] };
                        (this.store.state.bbs as Bbs).rubans.push(DefaultItemFarm)
                    }
                }
                break
        }
    }
}
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
