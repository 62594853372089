
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { assertNewExpression } from '@babel/types';
import { InputHTMLAttributes } from 'vue';
import Kana from './Kana/Kana.vue'
import Lesson from './Lesson/Lesson.vue'
@Options({
    props: {},
    components: {
        Kana,
        Lesson
    }
})
export default class MyJapanWorld extends Vue {
    
}
