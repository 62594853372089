import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import { User } from './interfaces/User'
import { Manga } from './interfaces/Manga'
import { Anime } from './interfaces/Anime'
import { Bbs } from './interfaces/Bbs'

// define your typings for the store state
export interface State {
    TextConfig: any | null
    User: User | null
    AnilistData: any | null
    Pokemon: any[]
    Mangas: Manga[] | null
    Animes: Anime[] | null
    Module: string
    Modules: any[]
    ModulesFilted: any[]
    Shortcuts: any[]
    BaseUrl: string
    bbs: Bbs
    openedModalId: string
    NeedToReload: boolean
    LastFetch: Date
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    TextConfig: null,
    User: null,
    AnilistData: null,
    Pokemon: [],
    Mangas: null,
    Animes: null,
    Module: "",
    Modules: [],
    ModulesFilted: [],
    Shortcuts: [],
    openedModalId: "",
    NeedToReload: false,
    LastFetch: new Date(),
    BaseUrl: "https://otakuworld.org/BackOtakuWorld/",
    bbs: {
      cristaux: [],
      gemmes: [],
      raid: [],
      rubans: [],
      larmes: [],
      album: [],
    }
  }
})