
type FiveStar = {
  get: boolean
  cristaux: boolean
  lv: number
}

type SixStar = {
  get: boolean,
  gemmes: boolean,
  raid: boolean,
  larmes: boolean,
  lv: number
}

type SixStarRes = {
  get: boolean,
  gemmes: boolean,
  raid: boolean,
  larmes: boolean,
  rubans: boolean,
  lv: number
}

type Potions = {
  slot1: number,
  slot2: number,
  slot3: number
}

type Revelation = {
  Type: string,
  Lv: number
}

type Revelations = {
  slot1: Revelation,
  slot2: Revelation,
  slot3: Revelation,
  slot4: Revelation,
  slot5: Revelation
}

export default class UnitBbs {
  id = 0
  special = 1
  "5S": FiveStar = {
    get: false,
    cristaux: false,
    lv: 1
  }
  "6S": SixStar = {
    get: false,
    gemmes: false,
    raid: false,
    larmes: false,
    lv: 1
  }
  "6SR": SixStarRes = {
    get: false,
    gemmes: false,
    raid: false,
    larmes: false,
    rubans: false,
    lv: 1
  }
  "potions": Potions = {
    slot1: 0,
    slot2: 0,
    slot3: 0
  }
  "revelation": Revelations = {
    slot1: {Type:"none", Lv:0},
    slot2: {Type:"none", Lv:0},
    slot3: {Type:"none", Lv:0},
    slot4: {Type:"none", Lv:0},
    slot5: {Type:"none", Lv:0},
  }

  setId(id: number) {
    this.id = id
  }
}
