import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d61bb5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  for: "basic-url",
  class: "form-label"
}
const _hoisted_2 = { class: "input-group mb-3" }
const _hoisted_3 = { class: "input-group-text" }
const _hoisted_4 = {
  for: "basic-url",
  class: "form-label"
}
const _hoisted_5 = { class: "input-group mb-3" }
const _hoisted_6 = { class: "input-group-text" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = {
  for: "basic-url",
  class: "form-label"
}
const _hoisted_9 = {
  class: "input-group mb-3",
  id: "AddAnimeModalSite"
}
const _hoisted_10 = { class: "input-group-text" }
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = {
  for: "basic-url",
  class: "form-label"
}
const _hoisted_13 = { class: "input-group mb-3" }
const _hoisted_14 = { class: "input-group-text" }
const _hoisted_15 = {
  for: "basic-url",
  class: "form-label"
}
const _hoisted_16 = { class: "input-group mb-3" }
const _hoisted_17 = { class: "input-group-text" }
const _hoisted_18 = { class: "input-group-text" }
const _hoisted_19 = {
  for: "basic-url",
  class: "form-label"
}
const _hoisted_20 = { value: "Watching" }
const _hoisted_21 = { value: "Stoped" }
const _hoisted_22 = { value: "On hold" }
const _hoisted_23 = { value: "Plan to watch" }
const _hoisted_24 = { value: "Finish" }
const _hoisted_25 = { class: "row" }
const _hoisted_26 = { class: "col" }
const _hoisted_27 = { class: "form-check-label" }
const _hoisted_28 = { value: "0" }
const _hoisted_29 = { value: "1" }
const _hoisted_30 = {
  value: "2",
  selected: ""
}
const _hoisted_31 = { class: "input-group mb-3 col" }
const _hoisted_32 = { class: "input-group-text" }
const _hoisted_33 = ["checked"]
const _hoisted_34 = { class: "input-group-text" }
const _hoisted_35 = { class: "input-group mb-3 col" }
const _hoisted_36 = { class: "input-group-text" }
const _hoisted_37 = ["checked"]
const _hoisted_38 = { class: "input-group-text" }
const _hoisted_39 = { class: "d-grid gap-2 col mx-auto" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].IdAnilist), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].IdAnilistSpan), 1),
      _withDirectives(_createElementVNode("input", {
        class: "form-control",
        type: "number",
        step: "1",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.Anime.anilistId) = $event)),
        placeholder: "0",
        "aria-label": "With input"
      }, null, 512), [
        [_vModelText, _ctx.Anime.anilistId]
      ])
    ]),
    _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].AlternatifName), 1),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].AlternatifNameSpan), 1),
      _withDirectives(_createElementVNode("input", {
        required: "",
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.Anime.alternativName) = $event)),
        placeholder: _ctx.store.state.TextConfig[_ctx.store.state.User.lang].AlternatifNamePlaceHolder,
        "aria-label": "NOM",
        "aria-describedby": "basic-AddAnimeon1"
      }, null, 8, _hoisted_7), [
        [_vModelText, _ctx.Anime.alternativName]
      ])
    ]),
    _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].AnimeSiteUrl), 1),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].AnimeSiteUrlSpan), 1),
      _withDirectives(_createElementVNode("input", {
        required: "",
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.Anime.link) = $event)),
        placeholder: _ctx.store.state.TextConfig[_ctx.store.state.User.lang].UrlPlaceHolder,
        "aria-label": "Server"
      }, null, 8, _hoisted_11), [
        [_vModelText, _ctx.Anime.link]
      ])
    ]),
    _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].LastEpisode), 1),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Episode), 1),
      _withDirectives(_createElementVNode("input", {
        class: "form-control",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.Anime.episode) = $event)),
        "aria-label": "With input"
      }, null, 512), [
        [_vModelText, _ctx.Anime.episode]
      ])
    ]),
    _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Date), 1),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Frequency), 1),
      _withDirectives(_createElementVNode("input", {
        class: "form-control",
        id: "AddAnimeFrequency",
        type: "number",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.Anime.frequency) = $event)),
        required: ""
      }, null, 512), [
        [_vModelText, _ctx.Anime.frequency]
      ]),
      _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Date), 1),
      _withDirectives(_createElementVNode("input", {
        class: "form-control",
        id: "AddAnimeDate",
        type: "date",
        required: "",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.Anime.date) = $event))
      }, null, 512), [
        [_vModelText, _ctx.Anime.date]
      ])
    ]),
    _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Statut), 1),
    _withDirectives(_createElementVNode("select", {
      class: "form-select mb-3",
      "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.Anime.statut) = $event)),
      "aria-label": "Default select example"
    }, [
      _createElementVNode("option", _hoisted_20, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].StatutWatch), 1),
      _createElementVNode("option", _hoisted_21, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].StatutStoped), 1),
      _createElementVNode("option", _hoisted_22, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].StatutOnHold), 1),
      _createElementVNode("option", _hoisted_23, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].StatutPlanToWatch), 1),
      _createElementVNode("option", _hoisted_24, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].StatutFinish), 1)
    ], 512), [
      [_vModelSelect, _ctx.Anime.statut]
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("label", _hoisted_27, [
          _withDirectives(_createElementVNode("select", {
            class: "form-select mb-3",
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.Anime.aJour) = $event))
          }, [
            _createElementVNode("option", _hoisted_28, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].NotToDate), 1),
            _createElementVNode("option", _hoisted_29, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].UpToDate), 1),
            _createElementVNode("option", _hoisted_30, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].WasToDate), 1)
          ], 512), [
            [_vModelSelect, _ctx.Anime.aJour]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_31, [
        _createElementVNode("div", _hoisted_32, [
          _withDirectives(_createElementVNode("input", {
            class: "form-check-input",
            type: "checkbox",
            checked: _ctx.Anime.doCheck,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.Anime.doCheck) = $event))
          }, null, 8, _hoisted_33), [
            [_vModelCheckbox, _ctx.Anime.doCheck]
          ])
        ]),
        _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].DoCheck), 1)
      ]),
      _createElementVNode("div", _hoisted_35, [
        _createElementVNode("div", _hoisted_36, [
          _withDirectives(_createElementVNode("input", {
            class: "form-check-input",
            type: "checkbox",
            checked: _ctx.Anime.currently == true,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.Anime.currently) = $event))
          }, null, 8, _hoisted_37), [
            [_vModelCheckbox, _ctx.Anime.currently]
          ])
        ]),
        _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].AnimeCurrently), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_39, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        onClick: _cache[10] || (_cache[10] = $event => (_ctx.AddAnime())),
        "data-bs-dismiss": "modal"
      }, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Add), 1)
    ])
  ], 64))
}