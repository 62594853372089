import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b25bb440"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  for: "basic-url",
  class: "form-label"
}
const _hoisted_2 = { class: "input-group mb-3" }
const _hoisted_3 = { class: "input-group-text" }
const _hoisted_4 = {
  for: "basic-url",
  class: "form-label"
}
const _hoisted_5 = { class: "input-group mb-3" }
const _hoisted_6 = { class: "input-group-text" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "input-group-text" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = {
  for: "basic-url",
  class: "form-label"
}
const _hoisted_11 = {
  class: "input-group mb-3",
  id: "AddMangaModalSite"
}
const _hoisted_12 = { class: "input-group-text" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "input-group-text" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = {
  for: "basic-url",
  class: "form-label"
}
const _hoisted_17 = { class: "input-group mb-3" }
const _hoisted_18 = { class: "input-group-text" }
const _hoisted_19 = {
  for: "basic-url",
  class: "form-label"
}
const _hoisted_20 = { class: "input-group mb-3" }
const _hoisted_21 = { class: "input-group-text" }
const _hoisted_22 = { class: "input-group-text" }
const _hoisted_23 = {
  for: "basic-url",
  class: "form-label"
}
const _hoisted_24 = { value: "Reading" }
const _hoisted_25 = { value: "Stoped" }
const _hoisted_26 = { value: "On hold" }
const _hoisted_27 = { value: "Plan to read" }
const _hoisted_28 = { value: "Finish" }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col" }
const _hoisted_31 = { class: "form-check-label" }
const _hoisted_32 = { value: "0" }
const _hoisted_33 = { value: "1" }
const _hoisted_34 = {
  value: "2",
  selected: ""
}
const _hoisted_35 = { class: "input-group mb-3 col" }
const _hoisted_36 = { class: "input-group-text" }
const _hoisted_37 = ["checked"]
const _hoisted_38 = { class: "input-group-text" }
const _hoisted_39 = { class: "input-group mb-3 col" }
const _hoisted_40 = { class: "input-group-text" }
const _hoisted_41 = ["checked"]
const _hoisted_42 = { class: "input-group-text" }
const _hoisted_43 = { class: "d-grid gap-2 col mx-auto" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].IdAnilist), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].IdAnilistSpan), 1),
      _withDirectives(_createElementVNode("input", {
        class: "form-control",
        type: "number",
        step: "1",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.Manga.anilistId) = $event)),
        placeholder: "0",
        "aria-label": "With input"
      }, null, 512), [
        [_vModelText, _ctx.Manga.anilistId]
      ])
    ]),
    _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].MangaAlternatifNameImage), 1),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].AlternatifNameSpan), 1),
      _withDirectives(_createElementVNode("input", {
        required: "",
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.Manga.alternativName) = $event)),
        placeholder: _ctx.store.state.TextConfig[_ctx.store.state.User.lang].AlternatifNamePlaceHolder,
        "aria-describedby": "basic-AddMangaon1"
      }, null, 8, _hoisted_7), [
        [_vModelText, _ctx.Manga.alternativName]
      ]),
      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].MangaAlternatifImageSpan), 1),
      _withDirectives(_createElementVNode("input", {
        required: "",
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.Manga.alternativImage) = $event)),
        placeholder: _ctx.store.state.TextConfig[_ctx.store.state.User.lang].UrlPlaceHolder
      }, null, 8, _hoisted_9), [
        [_vModelText, _ctx.Manga.alternativImage]
      ])
    ]),
    _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].MangaSiteMusicUrl), 1),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].MangaSiteUrlSpan), 1),
      _withDirectives(_createElementVNode("input", {
        required: "",
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.Manga.link) = $event)),
        placeholder: _ctx.store.state.TextConfig[_ctx.store.state.User.lang].UrlPlaceHolder,
        "aria-label": "Server"
      }, null, 8, _hoisted_13), [
        [_vModelText, _ctx.Manga.link]
      ]),
      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].MangaSiteMusicSpan), 1),
      _withDirectives(_createElementVNode("input", {
        required: "",
        type: "text",
        class: "form-control",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.Manga.songLink) = $event)),
        placeholder: _ctx.store.state.TextConfig[_ctx.store.state.User.lang].UrlPlaceHolder,
        "aria-label": "Username"
      }, null, 8, _hoisted_15), [
        [_vModelText, _ctx.Manga.songLink]
      ])
    ]),
    _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].LastScan), 1),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Scan), 1),
      _withDirectives(_createElementVNode("input", {
        class: "form-control",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.Manga.scan) = $event))
      }, null, 512), [
        [_vModelText, _ctx.Manga.scan]
      ])
    ]),
    _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Date), 1),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Frequency), 1),
      _withDirectives(_createElementVNode("input", {
        class: "form-control",
        id: "AddMangaFrequency",
        type: "number",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.Manga.frequency) = $event)),
        required: ""
      }, null, 512), [
        [_vModelText, _ctx.Manga.frequency]
      ]),
      _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Date), 1),
      _withDirectives(_createElementVNode("input", {
        class: "form-control",
        id: "AddMangaDate",
        type: "date",
        required: "",
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.Manga.date) = $event))
      }, null, 512), [
        [_vModelText, _ctx.Manga.date]
      ])
    ]),
    _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Statut), 1),
    _withDirectives(_createElementVNode("select", {
      class: "form-select mb-3",
      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.Manga.statut) = $event)),
      "aria-label": "Default select example"
    }, [
      _createElementVNode("option", _hoisted_24, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].StatutRead), 1),
      _createElementVNode("option", _hoisted_25, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].StatutStoped), 1),
      _createElementVNode("option", _hoisted_26, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].StatutOnHold), 1),
      _createElementVNode("option", _hoisted_27, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].StatutPlanToRead), 1),
      _createElementVNode("option", _hoisted_28, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].StatutFinish), 1)
    ], 512), [
      [_vModelSelect, _ctx.Manga.statut]
    ]),
    _createElementVNode("div", _hoisted_29, [
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("label", _hoisted_31, [
          _withDirectives(_createElementVNode("select", {
            class: "form-select mb-3",
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.Manga.aJour) = $event))
          }, [
            _createElementVNode("option", _hoisted_32, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].NotToDate), 1),
            _createElementVNode("option", _hoisted_33, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].UpToDate), 1),
            _createElementVNode("option", _hoisted_34, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].WasToDate), 1)
          ], 512), [
            [_vModelSelect, _ctx.Manga.aJour]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_35, [
        _createElementVNode("div", _hoisted_36, [
          _withDirectives(_createElementVNode("input", {
            class: "form-check-input",
            type: "checkbox",
            checked: _ctx.Manga.doCheck,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.Manga.doCheck) = $event))
          }, null, 8, _hoisted_37), [
            [_vModelCheckbox, _ctx.Manga.doCheck]
          ])
        ]),
        _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].DoCheck), 1)
      ]),
      _createElementVNode("div", _hoisted_39, [
        _createElementVNode("div", _hoisted_40, [
          _withDirectives(_createElementVNode("input", {
            class: "form-check-input",
            type: "checkbox",
            checked: _ctx.Manga.currently == true,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.Manga.currently) = $event))
          }, null, 8, _hoisted_41), [
            [_vModelCheckbox, _ctx.Manga.currently]
          ])
        ]),
        _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].MangaCurrently), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_43, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        onClick: _cache[12] || (_cache[12] = $event => (_ctx.AddManga())),
        "data-bs-dismiss": "modal"
      }, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Add), 1)
    ])
  ], 64))
}