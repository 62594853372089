import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-50 p-3 position-absolute top-50 start-50 translate-middle",
  style: {"background-color":"#eee"}
}
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "form-label" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "form-label" }
const _hoisted_7 = ["placeholder"]

export function render(_ctx, _cache) {
  return (_ctx.NotLogged == true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.store.state.TextConfig['eng'].Login), 1),
          _withDirectives(_createElementVNode("input", {
            type: "Login",
            placeholder: _ctx.store.state.TextConfig['eng'].Login,
            class: "form-control",
            name: "login",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.Form.Login) = $event))
          }, null, 8, _hoisted_4), [
            [_vModelText, _ctx.Form.Login]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.store.state.TextConfig['eng'].Password), 1),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            placeholder: _ctx.store.state.TextConfig['eng'].Password,
            class: "form-control",
            name: "password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.Form.Password) = $event))
          }, null, 8, _hoisted_7), [
            [_vModelText, _ctx.Form.Password]
          ])
        ]),
        _createElementVNode("button", {
          type: "submit",
          class: "btn btn-primary",
          onClick: _cache[2] || (_cache[2] = $event => (_ctx.Login()))
        }, _toDisplayString(_ctx.store.state.TextConfig['eng'].Submit), 1)
      ]))
    : _createCommentVNode("", true)
}