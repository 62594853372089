import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e5c33250"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "img-container" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "info" }
const _hoisted_4 = { class: "number" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "type" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pokemon", _ctx.IsCath]),
    onClick: _cache[0] || (_cache[0] = $event => (_ctx.Catch()))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.Pokemon.sprites.front_default,
        alt: _ctx.Pokemon.name
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.Pokemon.id), 1),
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.Pokemon.name), 1),
      _createElementVNode("small", _hoisted_6, "Type : " + _toDisplayString(_ctx.Pokemon.types[0].type.name), 1)
    ])
  ], 2))
}