import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9345d302"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "general-container" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "card logs-container col-4" }
const _hoisted_4 = { class: "card-header" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = {
  for: "Login",
  class: "form-label"
}
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = {
  for: "Password",
  class: "form-label"
}
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = {
  for: "PasswordConfirm",
  class: "form-label"
}
const _hoisted_12 = { class: "mb-3" }
const _hoisted_13 = {
  for: "DefaultModule",
  class: "form-label"
}
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-md-4" }
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "col-md-2" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = { class: "card logs-container col-2" }
const _hoisted_20 = { class: "card-header" }
const _hoisted_21 = { class: "card-body" }
const _hoisted_22 = { class: "module-drag" }

export function render(_ctx, _cache) {
  const _component_Draggable = _resolveComponent("Draggable")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].GeneralSettings), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("form", null, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Login), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.User.Login) = $event))
              }, null, 512), [
                [_vModelText, _ctx.User.Login]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Password), 1),
              _withDirectives(_createElementVNode("input", {
                type: "password",
                class: "form-control",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.User.Password) = $event))
              }, null, 512), [
                [_vModelText, _ctx.User.Password]
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].PasswordConfirm), 1),
              _withDirectives(_createElementVNode("input", {
                type: "password",
                class: _normalizeClass(["form-control", _ctx.PasswordsMatch?'':'passwords-dont-match']),
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.User.PasswordConfirm) = $event))
              }, null, 2), [
                [_vModelText, _ctx.User.PasswordConfirm]
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].DefaultModule), 1),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select",
                    name: "DefaultModule",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.User.DefaultModule) = $event))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.Modules, (Module) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: Module.id
                      }, _toDisplayString(Module.name), 9, _hoisted_16))
                    }), 256))
                  ], 512), [
                    [_vModelSelect, _ctx.User.DefaultModule]
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-primary",
                    onClick: _cache[4] || (_cache[4] = $event => (_ctx.SaveLogs())),
                    disabled: _ctx.PasswordsMatch == false
                  }, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].Save), 9, _hoisted_18)
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.store.state.TextConfig[_ctx.store.state.User.lang].ModulesDisposition), 1),
        _createElementVNode("div", _hoisted_21, [
          _createVNode(_component_Draggable, {
            modelValue: _ctx.store.state.Modules,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.store.state.Modules) = $event)),
            group: "people",
            onStart: _cache[6] || (_cache[6] = $event => (_ctx.drag=true)),
            onEnd: _cache[7] || (_cache[7] = $event => (_ctx.drag=false)),
            "item-key": "id"
          }, {
            item: _withCtx(({element}) => [
              _createElementVNode("div", _hoisted_22, _toDisplayString(element.name), 1)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ])
    ])
  ]))
}