
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { User } from '@/interfaces/User'
import Popper from "vue3-popper";

@Options({
    props: {
        Character: {}
    },
    components: {
        Popper
    }
})
export default class UnitInformation extends Vue {
    Character!: any

    FillterSkillInfo() {
        let array: any[] = []
        this.Character.banners[0].tree.breakdown.forEach((breakdown: any) => {
            if (array.find((skill) => skill.name == breakdown.name) == null) {
                array.push({ name: breakdown.name, detail: [breakdown.detail] })
            } else {
                array[array.findIndex((skill) => skill.name == breakdown.name)].detail.push(breakdown.detail)
            }
        });
        return array
    }
}
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
