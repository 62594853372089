
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { User } from '@/interfaces/User'
import { Bbs } from '@/interfaces/Bbs'
import UnitBBS from './UnitBBS/UnitBBS.vue'
import ListUnitFarm from './ListUnitFarm/ListUnitFarm.vue'
import SelectUnitAlbum from './SelectUnitAlbum/SelectUnitAlbum.vue'
import { Offcanvas } from 'bootstrap'
import { TextareaHTMLAttributes } from 'vue';
import UnitBbs from '@/Class/UnitBbs.vue'

@Options({
    props: {},
    components: {
        UnitBBS,
        ListUnitFarm,
        SelectUnitAlbum
    }
})
export default class MyBBS extends Vue {
    store = useStore(key)
    AllCharacter: any[] = []
    Index = 0
    ChildMountedCounter = 0
    AllChildMounted = false
    Limit = 96
    Search = ""
    AlmbumMode = false
    SelectMode = false
    AllCharacterSelected: any[] = []
    AllIdCharacterSelected: any[] = []
    addUnit = ""
    addResu = ""
    mounted() {
        this.scroll()
        axios.get(this.store.state.BaseUrl + 'MyBBS/UserData/' + this.store.state.User?.id + '.json')
            .then((response) => {
                this.store.state.bbs = response.data as Bbs
            }).catch((e) => {
                console.log(e)
            })

        this.$watch('AllCharacter', () => {
            axios.post((this.store.state.BaseUrl + 'MyBBS/UploadCharacters.php'), {
                Bbs: JSON.stringify(this.AllCharacter)
            })
        }, { deep: true })

        this.$watch('store.state.bbs', () => {
            axios.post((this.store.state.BaseUrl + 'MyBBS/UpdateBbs.php'), {
                User: this.store.state.User,
                Bbs: this.store.state.bbs
            })
            this.$forceUpdate()
        }, { deep: true })

        axios.get(this.store.state.BaseUrl + 'MyBBS/BBS.json')
            .then((response) => {
                JSON.parse(response.data).forEach((element: any) => {
                    console.log(element)
                    if (element != null) {
                        this.AllCharacter.push(element)
                        this.AllCharacter.sort(this.SortUnitById);
                    }
                });
            }).catch((e) => {
                console.log(e)
            })
        if ((this.store.state.User as any).role <= 1) {
            const offCanvas = new Offcanvas(this.$refs.OffcanvasEdit as any)
            window.addEventListener("keydown", e => {
                if (e.key == '²') {
                    offCanvas.toggle(this.$refs.OffcanvasEdit as any)
                }
                if (e.key == 'Enter') {
                    if (document.getElementById("addBbsUnit") == document.activeElement) {
                        this.AllCharacter.push(JSON.parse(this.addUnit))
                        this.AllCharacter.sort(this.SortUnitById)
                        this.addUnit = ""
                    } else if (document.getElementById("addBbsResu") == document.activeElement) {
                        const index = this.AllCharacter.findIndex((element) => element.banners[0].album_id == JSON.parse(this.addResu).banners[0].album_id)
                        if(index != -1){
                            this.AllCharacter[index] = JSON.parse(this.addResu)
                            this.addResu = ""
                        }
                    }
                }
            });
        }
    }

    ChildMounted() {
        this.ChildMountedCounter += 1
        if (this.ChildMountedCounter >= this.Limit) {
            this.AllChildMounted = true
        }
    }

    FilterUnit() {
        //return this.AllCharacter.filter(element => element.name.toLowerCase().includes(this.Search.toLowerCase())).slice(0, this.Limit)
        return this.AllCharacter
    }

    SortUnitById(a: any, b: any) {
        if (a.banners[0].album_id > b.banners[0].album_id) {
            return -1;
        }
        if (a.banners[0].album_id < b.banners[0].album_id) {
            return 1;
        }
        return 0;
    }

    scroll() {
        window.onscroll = () => {
            let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight

            if (bottomOfWindow && this.Limit < this.AllCharacter.length) {
                this.Limit += 48
            }
        }
    }

    CharacterSelected(div: any) {
        if (this.SelectMode) {
            div.path.forEach((element: any) => {
                if (element.id != null) {
                    if (element.id.includes('UnitBbsSelector')) {
                        const findIndex = this.AllIdCharacterSelected.findIndex((IdElement) => IdElement == element.id.replace('UnitBbsSelector', ''))
                        if (findIndex == -1) {
                            this.AllCharacterSelected.push((this.$refs[element.id.replace('Selector', '')] as any)[0].Album)
                            this.AllIdCharacterSelected.push((this.$refs[element.id.replace('Selector', '')] as any)[0].Album.id)
                        } else {
                            this.AllCharacterSelected.splice(findIndex, 1)
                            this.AllIdCharacterSelected.splice(findIndex, 1)
                        }
                    }
                }
            })
        }

    }

    IsCharacterSelected(index: number) {
        return this.AllIdCharacterSelected.includes(this.FilterUnit()[index].id)
    }
}
