
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'

@Options({
    props: {},
    components: {
    }
})
export default class DiscordAttack extends Vue {
    store = useStore(key)
    url = ""
    username = ""
    message = ""
    tts = false
    On = false

    mounted() {
        setInterval(function (this: any) {
            if (this.On) {
                axios.post(
                    this.store.state.BaseUrl + 'DiscordAttack/CreateWebHoookMessage.php',
                    {
                        url: this.url,
                        message: this.message,
                        username: this.username,
                        TTS: this.tts
                    },
                )
            }
        }.bind(this), 500);
    }
}
