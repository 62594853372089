
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { Offcanvas } from 'bootstrap'

@Options({
    props: {
        Module: {}
    }
})
export default class Module extends Vue {
    store = useStore(key)
    Module!: any
    hover = false
    ChangeModule(){
        if(this.Module.link != null){
            window.location.replace(this.Module.link);
        }else{
            this.store.state.Module = this.Module.module
        }
        console.log(this.store.state.Module)
    }
}
