
import { Options, Vue } from 'vue-class-component';
import MangaCard from './MangaCard/MangaCard.vue';
import AnimeCard from './AnimeCard/AnimeCard.vue';
import Navbar from './Navbar/Navbar.vue';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { Manga } from '@/interfaces/Manga'
import { Anime } from '@/interfaces/Anime'

@Options({
    props: {},
    components: {
        MangaCard,
        AnimeCard,
        Navbar
    },
})
export default class MyMangaAnime extends Vue {
    store = useStore(key)
    SortMangaStatut = ["Reading", "On hold", "Stoped", "Plan to read", "Finish"]
    SortAnimeStatut = ["Watching", "On hold", "Stoped", "Plan to watch", "Finish"]
    mangaSearch = ""
    animeSearch = ""

    async mounted() {
        this.Fetch()
        document.addEventListener("visibilitychange", e => {
            if (document.visibilityState === "visible") {
                this.OnFocus();
            }
        });

        setInterval(this.CheckCronUpdate, 1000);
    }

    public async Fetch(){
        if (localStorage.getItem("AnilistData")) {
            this.store.state.AnilistData = JSON.parse(localStorage.getItem("AnilistData") as string)
        } else {
            this.store.state.AnilistData = { AnilistManga: {}, AnilistAnime: {} }
            localStorage.setItem("AnilistData", JSON.stringify(this.store.state.AnilistData))
        }

        this.$watch('store.state.AnilistData', () => {
            localStorage.setItem("AnilistData", JSON.stringify(this.store.state.AnilistData))
        }, { deep: true })

        this.$watch('store.state.Mangas.length', () => {
            this.SortBy("alpha", "Manga")
            this.SortBy("statut", "Manga")
            this.SortBy("aJour", "Manga")
        }, { deep: true })

        this.$watch('store.state.Animes.length', () => {
            this.SortBy("alpha", "Anime")
            this.SortBy("statut", "Anime")
            this.SortBy("aJour", "Anime")
        }, { deep: true })

        this.$watch('store.state.openedModalId', () => {
            if(this.store.state.openedModalId == "" && this.store.state.NeedToReload){
                this.Fetch()
                this.store.state.NeedToReload = false;
            }
        })

        await axios.post(
            this.store.state.BaseUrl + 'MyMangaAnime/FetchManga.php',
            {
                User: this.store.state.User,
            },
        )
            .then((response) => {
                this.store.state.Mangas = (response.data as Manga[])
            }).catch(function (error) {
                console.log(error)
            });

        await axios.post(
            this.store.state.BaseUrl + 'MyMangaAnime/FetchAnime.php',
            {
                User: this.store.state.User,
            },
        )
        .then((response) => {
            this.store.state.Animes = (response.data as Anime[])
        }).catch(function (error) {
            console.log(error)
        });
        
        this.store.state.LastFetch = new Date()
    }

    public SortBy(sortBy: string, Type: string) {
        var ArrayStatut: string[] = []
        var Sorted: any[] = []

        if (Type == "Manga") {
            ArrayStatut = this.SortMangaStatut
            Sorted = (this.store.state.Mangas as Manga[])
        } else if (Type == "Anime") {
            ArrayStatut = this.SortAnimeStatut
            Sorted = (this.store.state.Animes as Anime[])
        }

        switch (sortBy) {
            case 'alpha':
                Sorted = Sorted.sort((a, b) => (a.alternativName > b.alternativName) ? 1 : ((b.alternativName > a.alternativName) ? -1 : 0));
                break;
            case 'statut':
                Sorted = Sorted.sort((a, b) => ArrayStatut.indexOf(a.statut) - ArrayStatut.indexOf(b.statut))
                break;
            case 'aJour':
                Sorted = Sorted.sort((a, b) => (a.aJour < b.aJour) ? 1 : ((b.aJour < a.aJour) ? -1 : 0));
                break;
        }
    }

    public MangaCurrently(bool: string) {
        let listOfManga: any[] = []
        this.store.state.Mangas?.forEach((manga) => {
            let anilistTitle = ""
            if (this.store.state.AnilistData.AnilistManga[manga.anilistId]?.data?.Media?.title?.english) {
                anilistTitle = this.store.state.AnilistData.AnilistManga[manga.anilistId].data.Media.title.english
            }
            if (manga.currently == bool && ( manga.alternativName.toLowerCase().includes(this.mangaSearch.toLowerCase()) || anilistTitle.includes(this.mangaSearch) )) {
                listOfManga.push(manga)
            }
        })
        return listOfManga;
    }

    public AnimeCurrently(bool: string) {
        let listOfAnime: any[] = []
        this.store.state.Animes?.forEach((anime) => {
            let anilistTitle = ""
            if (this.store.state.AnilistData.AnilistAnime[anime.anilistId]?.data?.Media?.title?.english) {
                anilistTitle = this.store.state.AnilistData.AnilistAnime[anime.anilistId].data.Media.title.english
            }
            if (anime.currently == bool && ( anime.alternativName.toLowerCase().includes(this.animeSearch.toLowerCase()) || anilistTitle.includes(this.animeSearch) )) {
                listOfAnime.push(anime)
            }
        })
        return listOfAnime;
    }

    public IsThereMangaCurrently() {
        return this.store.state.Mangas?.filter((manga) => manga.currently == 'true').length
    }

    public IsThereManga() {
        return this.store.state.Mangas?.filter((manga) => manga.currently == 'false').length
    }

    public IsThereAnimeCurrently() {
        return this.store.state.Animes?.filter((anime) => anime.currently == 'true').length
    }

    public IsThereAnime() {
        return this.store.state.Animes?.filter((anime) => anime.currently == 'false').length
    }

    public OnFocus(){
        this.Reload()
    }

    public CheckCronUpdate(){

        const CronTime: Date = new Date();
        CronTime.setHours(11, 0, 0, 0);

        const delta: number = new Date().getTime() - CronTime.getTime();
        
        if (delta < -5000 || delta > 5000) {
            return;
        }

        console.log("test");
        axios.post(
            this.store.state.BaseUrl + 'MyMangaAnime/FetchLastUserCronUpdate.php',
            {
                User: this.store.state.User,
            },
        )
            .then((response) => {
                if(this.store.state.LastFetch < new Date(response.data as string)){
                    this.Reload();
                }
            }).catch(function (error) {
                console.log(error)
            });
    }

    public Reload(){
        if (this.store.state.openedModalId == "") {
            this.Fetch();
        }else{
            this.store.state.NeedToReload = true;
        }
    }
}
