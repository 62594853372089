import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fc3fb3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { "aria-label": "breadcrumb" }
const _hoisted_3 = { class: "breadcrumb" }
const _hoisted_4 = {
  key: 1,
  class: "breadcrumb-item active"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }

export function render(_ctx, _cache) {
  return (_ctx.Lessons != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("nav", _hoisted_2, [
          _createElementVNode("ol", _hoisted_3, [
            _createElementVNode("li", {
              class: "breadcrumb-item",
              onClick: _cache[0] || (_cache[0] = $event => (_ctx.ChoosenLesson = null, _ctx.ChoosenSection = null))
            }, "Home"),
            (_ctx.ChoosenLesson != null)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: "breadcrumb-item",
                  onClick: _cache[1] || (_cache[1] = $event => (_ctx.ChoosenSection = null))
                }, _toDisplayString(_ctx.ChoosenLesson), 1))
              : _createCommentVNode("", true),
            (_ctx.ChoosenSection != null)
              ? (_openBlock(), _createElementBlock("li", _hoisted_4, _toDisplayString(_ctx.ChoosenSection), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.ChoosenLesson == null)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.Lessons, (Lesson) => {
              return (_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", {
                  onClick: $event => (_ctx.ChoosenLesson = Lesson.title)
                }, _toDisplayString(Lesson.title), 9, _hoisted_5)
              ]))
            }), 256))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_ctx.ChoosenSection == null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", {
                      onClick: _cache[2] || (_cache[2] = $event => (_ctx.ChoosenSection = 'lesson'))
                    }, "Leçon"),
                    _createElementVNode("div", {
                      onClick: _cache[3] || (_cache[3] = $event => (_ctx.ChoosenSection = 'vocabulary'))
                    }, "Vocabulaire"),
                    _createElementVNode("div", {
                      onClick: _cache[4] || (_cache[4] = $event => (_ctx.ChoosenSection = 'exo'))
                    }, "Exercice")
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_ctx.ChoosenSection == 'lesson')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, " c est les exo de la lesson " + _toDisplayString(_ctx.ChoosenLesson), 1))
                      : (_ctx.ChoosenSection == 'vocabulary')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, " c est le vocab de la lesson " + _toDisplayString(_ctx.ChoosenLesson), 1))
                        : (_ctx.ChoosenSection == 'exo')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, " c est les exo de la lesson " + _toDisplayString(_ctx.ChoosenLesson), 1))
                          : _createCommentVNode("", true)
                  ]))
            ]))
      ]))
    : _createCommentVNode("", true)
}