
import { Options, Vue } from 'vue-class-component';
import { Manga } from '@/interfaces/Manga'
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import MangaClass from '@/Class/Manga.vue'
import axios from 'axios'

@Options({
    props: {},
    components: {
    },
})

export default class AddManga extends Vue {
    store = useStore(key)
    Manga = new MangaClass(this.store.state.User!.id)

    mounted(){
        this.$watch('Manga.currently', () => {
            if (this.Manga.currently != "true" && this.Manga.currently != "false") {
                this.Manga.currently = this.Manga.currently.toString()
            }
        })
    }

    AddManga() {
        axios.post(
            this.store.state.BaseUrl + 'MyMangaAnime/AddManga.php',
            {
                Manga: this.Manga
            },
        ).then(async (response) => {
            this.Manga.id = response.data
            if (this.Manga.anilistId != 0) {
                await this.queryAnilist()
                this.UpdateAniliste()
            } else {
                this.store.state.Mangas?.push(this.Manga)
                this.Manga = new MangaClass(this.store.state.User!.id)
            }

        }).catch(function (error) {
            console.log(error)
        });
    }

    queryAnilist() {
        const query = `
    query ($id: Int) { # Define which variables will be used in the query (id)
      Media (id: $id, type: MANGA) { # Insert our variables into the query arguments (id) (type: ANIME is hard-coded in the query)
        id
        bannerImage
        title {
          romaji
          english
          native
        }
        coverImage{
          large
          medium
        }
      }
    }
    `;

        const variables = {
            id: this.Manga.anilistId
        };
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };
        axios.post(
            'https://graphql.anilist.co',
            {
                query: query,
                variables: variables
            },
        )
            .then((response) => {
                this.store.state.AnilistData.AnilistManga[this.Manga.anilistId] = response.data
                this.store.state.Mangas?.push(this.Manga)
                this.Manga = new MangaClass(this.store.state.User!.id)
            }).catch(function (error) {
                console.log(error)
            });
    }

    UpdateAniliste() {
        if (this.Manga.anilistId == 0 && this.store.state.User?.anilist_token == null) return

        let status = "CURRENT"
        switch (this.Manga.statut) {
            case "Reading":
                status = "CURRENT"
                break;
            case "Stoped":
                status = "DROPPED"
                break;
            case "On hold":
                status = "PAUSED"
                break;
            case "Plan to read":
                status = "PLANNING"
                break;
            case "Finish":
                status = "COMPLETED"
                break;
        }
        const query = `
                    mutation($mediaId: Int,$progress: Int, $status: MediaListStatus) {
                        SaveMediaListEntry(mediaId: $mediaId,progress: $progress, status: $status) {
                            id
                            status
                        }
                    }
                    `;
        const variables = {
            "progress": this.Manga.scan,
            "mediaId": this.Manga.anilistId,
            "status": status
        };
        var options = {
            uri: 'https://graphql.anilist.co',
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.store.state.User?.anilist_token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                'query': query,
                variables: variables
            })
        };
        fetch("https://graphql.anilist.co", options as any)
    }
}
