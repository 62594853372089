import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "account-container" }

export function render(_ctx, _cache) {
  const _component_General = _resolveComponent("General")
  const _component_Notification = _resolveComponent("Notification")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_General),
    _createVNode(_component_Notification)
  ]))
}