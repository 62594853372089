
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { Bbs } from '@/interfaces/Bbs'

@Options({
    props: {},
    components: {
    }
})
export default class ListUnitFarm extends Vue {
    store = useStore(key)
    cristalsGemmesColor = ["Vert", "Violet", "Rouge", "Bleu", "Orange"]
    cristalsGemmesSize = ["P", "M", "G"]
    Webhook = "https://discord.com/api/webhooks/1004011830030643251/DTHGDKKMSkxlI-_m6wad2FD4JZ2MIEVGy7J3a0qeBWy-6o7LfyZAPBe9dbxKQOUdv6wz"

    mounted() {
        console.log(this.store.state.bbs)
    }

    GetAllCharacter(type: "cristaux" | "gemmes" | "raid" | "larmes" | "rubans") {
        let ListCharacterFarm: any[] = []
        switch (type) {
            case "cristaux":
            case "gemmes":
            case "larmes":
                ListCharacterFarm = (this.store.state.bbs as Bbs)[type][0].characterImage
                break
            case "raid":
                (this.store.state.bbs as Bbs).raid.forEach(element => {
                    element.characterImage.forEach((charaterFarm: any) => {
                        const contains = ListCharacterFarm.some(elem => {
                            return JSON.stringify(charaterFarm) === JSON.stringify(elem);
                        });
                        if (!contains) {
                            ListCharacterFarm.push(charaterFarm)
                        }

                    })
                })
                break
            case "rubans":
                (this.store.state.bbs as Bbs).rubans.forEach(element => {
                    element.characterImage.forEach((charaterFarm: any) => {
                        ListCharacterFarm.push(charaterFarm)
                    })
                })
                break
        }
        return ListCharacterFarm
    }

    IsFarmListEmpty() {
        const bbs = this.store.state.bbs
        return (bbs.cristaux.length == 0 && bbs.gemmes.length == 0 && bbs.raid.length == 0 && bbs.larmes.length == 0 && bbs.rubans.length == 0)
    }

    sendMessage() {
        const request = new XMLHttpRequest();
        request.open("POST", this.Webhook);

        request.setRequestHeader('Content-type', 'application/json');

        const embeds = []
        const bbs = this.store.state.bbs

        if (bbs.cristaux.length > 0) {
            embeds.push(this.CreateCristalsEmbed(bbs))
        }
        if (bbs.gemmes.length > 0) {
            embeds.push(this.CreateGemmesEmbed(bbs))
        }
        if (bbs.larmes.length > 0) {
            embeds.push(this.CreateLarmesEmbed(bbs))
        }

        var params = {
            username: "BBS Farming Simulator",
            avatar_url: "",
            embeds: embeds
        }

        request.send(JSON.stringify(params));
    }

    CreateCristalsEmbed(bbs: any) {
        var cristaux = {
            title: "Cristaux",
            description: "",
        }
        for (let index = 0; index < 5; index++) {
            cristaux.description += (
                "```\n" +
                this.cristalsGemmesColor[index] + " => " +
                this.cristalsGemmesSize[0] + " " + bbs.cristaux[(index * 3)].amount + " | " +
                this.cristalsGemmesSize[1] + " " + bbs.cristaux[(index * 3) + 1].amount + " | " +
                this.cristalsGemmesSize[2] + " " + bbs.cristaux[(index * 3) + 2].amount + " | " +
                "\n```"
            )
        }
        return cristaux
    }

    CreateGemmesEmbed(bbs: any) {
        var gemmes = {
            title: "Gemmes",
            description: "",
        }
        for (let index = 0; index < 5; index++) {
            gemmes.description += (
                "```\n" +
                this.cristalsGemmesColor[index] + " => " +
                this.cristalsGemmesSize[0] + " " + bbs.gemmes[(index * 3)].amount + " | " +
                this.cristalsGemmesSize[1] + " " + bbs.gemmes[(index * 3) + 1].amount + " | " +
                this.cristalsGemmesSize[2] + " " + bbs.gemmes[(index * 3) + 2].amount + " | " +
                "\n```"
            )
        }
        return gemmes
    }

    CreateLarmesEmbed(bbs: any) {
        var larmes = {
            title: "Larmes",
            description: "",
        }
        for (let index = 0; index < 5; index++) {
            larmes.description += (
                "```\n" +
                this.cristalsGemmesColor[index] + " => " +
                bbs.larmes[index].amount +
                "\n```"
            )
        }
        return larmes
    }

    RemoveFromList(Unit: any, type: "cristaux" | "gemmes" | "raid" | "larmes" | "rubans") {
        const indexToRemove = []

        for (let index = 0; index < Unit.Items.length; index++) {
            const Index = this.store.state.bbs[type].findIndex((element: any) => element.image == Unit.Items[index].image)
            this.store.state.bbs[type][Index].amount -= Unit.Items[index].amount
            this.store.state.bbs[type][Index].characterImage.splice(this.store.state.bbs[type][Index].characterImage.findIndex((element: any) => element == Unit), 1)
            if (this.store.state.bbs[type][Index].amount == 0) {
                indexToRemove.push(this.store.state.bbs[type][Index])
            }
        }
        indexToRemove.forEach((element) => {
            this.store.state.bbs[type].splice(this.store.state.bbs[type].findIndex((ElementStore) => ElementStore == element), 1)
        })
    }
}
