
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'

@Options({
    props: {},
    components: {
    }
})
export default class MyGameWorld extends Vue {
    store = useStore(key)
    Games: any[] = []
    GameSelected = ""

    mounted() {
        axios.get(this.store.state.BaseUrl + 'MyGames/FetchGames.php')
            .then((response) => {
                this.Games = response.data
            }).catch((e) => {
                console.log(e)
            })
    }

    SelectGame(Game:any){
        this.GameSelected = Game.directory
    }

    unmounted() {
        this.GameSelected = ""
    }
}
