
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import MangathequeClass from '@/Class/Mangatheque.vue'
import { ItemsClass } from '@/Class/Mangatheque.vue'
@Options({
    props: {},
    components: {
    }
})
export default class MyMangatheque extends Vue {
    store = useStore(key)
    searchBar = "";
    mangathequeSeries: MangathequeClass[] = []
    EditSerie: MangathequeClass | null = null

    async mounted() {
        if (this.store.state.User != null) {
            if (this.store.state.User.mangatheque != null) {
                this.mangathequeSeries = this.store.state.User.mangatheque as MangathequeClass[]
                this.EditSerie = this.mangathequeSeries[0];
            } else {
                this.store.state.User.mangatheque = []
                this.mangathequeSeries = this.store.state.User.mangatheque
            }
        }

        this.$watch("mangathequeSeries", () => {
            if (this.store.state.User != null) {
                this.store.state.User.mangatheque = this.mangathequeSeries;
                axios.post(
                    this.store.state.BaseUrl + 'MyMangatheque/UpdateMangatheque.php',
                    {
                        User: this.store.state.User,
                    },
                )
            }
        }, { deep: true })
    }

    mangathequeSeriesFiltered() {
        return this.mangathequeSeries.filter((element: any) => element.name.includes(this.searchBar))
    }

    GetBgColor(item: any): string {
        switch (item.statut) {
            case "NotOut":
                return "bg-secondary"
            case "Out":
                return "bg-primary"
            case "NotAvailable":
                return "bg-dark"
            case "Get":
                return "bg-success"
            case "Commanded":
                return "bg-warning"
            case "SuposedToBeArrived":
                return "bg-danger"
            default:
                return ""
        }
    }

    addSerie() {
        if (this.store.state.User != null) {
            this.mangathequeSeries.push(new MangathequeClass(this.store.state.User.id))
            this.EditSerie = this.mangathequeSeries[this.mangathequeSeries.length - 1]
        }

    }

    RemoveSerie() {
        const index = this.mangathequeSeries.findIndex((element: any) => element == this.EditSerie)
        this.mangathequeSeries.splice(index, 1)
        if (this.mangathequeSeries.length > 0) {
            this.EditSerie = this.mangathequeSeries[0]
        } else {
            this.EditSerie = null
        }
    }

    AddItem() {
        this.EditSerie?.items.push(new ItemsClass(this.EditSerie?.items.length+1))
    }

    RemoveItem(index: number) {
        this.EditSerie?.items.splice(index, 1)
    }
}
