import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_Login = _resolveComponent("Login")
  const _component_Modules = _resolveComponent("Modules")
  const _component_MyMangaAnime = _resolveComponent("MyMangaAnime")
  const _component_MyPokeWorld = _resolveComponent("MyPokeWorld")
  const _component_MyAccount = _resolveComponent("MyAccount")
  const _component_MyAdminPanel = _resolveComponent("MyAdminPanel")
  const _component_MyBBS = _resolveComponent("MyBBS")
  const _component_MyGameWorld = _resolveComponent("MyGameWorld")
  const _component_MyMangatheque = _resolveComponent("MyMangatheque")
  const _component_TestWorld = _resolveComponent("TestWorld")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.store.state.User == null)
      ? (_openBlock(), _createBlock(_component_Login, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.store.state.User != null)
      ? (_openBlock(), _createBlock(_component_Modules, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.store.state.Module == 'MangaAnime')
      ? (_openBlock(), _createBlock(_component_MyMangaAnime, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.store.state.Module == 'Pokemon')
      ? (_openBlock(), _createBlock(_component_MyPokeWorld, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.store.state.Module == 'Account')
      ? (_openBlock(), _createBlock(_component_MyAccount, { key: 4 }))
      : _createCommentVNode("", true),
    (_ctx.store.state.Module == 'Admin')
      ? (_openBlock(), _createBlock(_component_MyAdminPanel, { key: 5 }))
      : _createCommentVNode("", true),
    (_ctx.store.state.Module == 'BBS')
      ? (_openBlock(), _createBlock(_component_MyBBS, { key: 6 }))
      : _createCommentVNode("", true),
    (_ctx.store.state.Module == 'Game')
      ? (_openBlock(), _createBlock(_component_MyGameWorld, { key: 7 }))
      : _createCommentVNode("", true),
    (_ctx.store.state.Module == 'Mangatheque')
      ? (_openBlock(), _createBlock(_component_MyMangatheque, { key: 8 }))
      : _createCommentVNode("", true),
    (_ctx.store.state.Module == 'TestWorld')
      ? (_openBlock(), _createBlock(_component_TestWorld, { key: 9 }))
      : _createCommentVNode("", true)
  ], 64))
}