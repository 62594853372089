import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ff32600"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "card-title" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: "card bg-secondary module-card",
    onClick: _cache[0] || (_cache[0] = $event => (_ctx.ChangeModule())),
    onMouseover: _cache[1] || (_cache[1] = $event => (_ctx.hover = true)),
    onMouseleave: _cache[2] || (_cache[2] = $event => (_ctx.hover = false))
  }, [
    (_ctx.hover == true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            src: _ctx.Module.banner,
            class: "card-img-top",
            alt: ""
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.Module.name), 1)
    ])
  ], 32))
}