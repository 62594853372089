import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Home = _resolveComponent("Home")!
  const _component_MyJapanWorld = _resolveComponent("MyJapanWorld")!
  const _component_DiscordAttack = _resolveComponent("DiscordAttack")!

  return (_ctx.store.state.TextConfig != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.page == '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Home)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          (_ctx.page == 'JapanWorld')
            ? (_openBlock(), _createBlock(_component_MyJapanWorld, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.page == 'DiscordAttack')
            ? (_openBlock(), _createBlock(_component_DiscordAttack, { key: 1 }))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}