
import { Options, Vue } from 'vue-class-component';
import { storeKey, useStore } from 'vuex'
import { key } from '@/store'
import axios from 'axios'
import { hide } from '@popperjs/core';

@Options({
    props: {},
    components: {
    }
})
export default class TestWorld extends Vue {
    
}
